import { calc_pw, calc_fit, asyncImage, parseQuery } from './lib'
import { Brick } from './Brick';
import { allRoundEasing, easeOutCubic, easeOutQuart } from './easing';
import { map as loMap, random, shuffle, sample, defaultsDeep } from 'lodash';
import axios from 'axios';

import ShapeBuilder from './ShapeBuilder';

let main_img;
let surface_img;
let brick_imgs = [];
let activity = parseQuery()['activity'] || "2210_zhqwtgby";
let main_activity = parseQuery()['main_activity'] || activity;
// let activity = parseQuery()['activity'] || "2207_woiqumbq";
let nrows = parseInt(parseQuery()['rows']) || 9;
let ncols = parseInt(parseQuery()['cols']) || 16;
let loops = parseInt(parseQuery()['loops']||'1') ;
let alpha = parseFloat(parseQuery()['alpha']||'1') ;
let debug = !!parseQuery()['debug'];
let max_bricks = parseInt(parseQuery()['nums']) || nrows*ncols;
// let max_bricks = 10;
let brick_width, brick_height, cols, rows;
let tTop, tLeft, tHeight, tWidth, tTopFix, tLeftFix ;
let started = false;
let bricks = [];
let promiseLock = null;
let promiseLockResolve = null;
let scene_frames = 1;
let montage_image_data =[];
let montage_order = shuffle([...Array(max_bricks).keys()]);
let montage_processed = {};
let shape_builder = new ShapeBuilder();
let effect_name = '';
let fade_img = {};
shape_builder.init();

async function brick_init(){
  bricks = [...Array(max_bricks).keys()].map(idx=>new Brick({idx}));
}

async function start_fade(url){
  effect_name = "fade";
  main_img = await asyncImage(url);
  const txs_fn = (start, end, percentage, localStart=0, localEnd=1)=>{
    // console.log(percentage, localStart, localEnd);
    return allRoundEasing(percentage, localStart, localEnd, easeOutQuart, 0, 1)*(end-start)+start;
  }

  return new Promise(res=>{
      fade_img = {

      draw: (percentage)=>{
        if(percentage>=1){
          res();
        }
        // console.log('draw perentage', percentage);
        push();
        tint(255, txs_fn(0,255,percentage)); // Display at half opacity
        image(main_img, tWidth/-2, tHeight/-2, tWidth, tHeight)
      //   texture(main_img);

      //   box(
      //     tWidth,
      //     tHeight,
      //     10
      //     );

      //     pop();
      },
    }

    frameCount = 0;
    window.draw=mydraw;
    started=true;
  });
}

async function start_img(url, counts){
  effect_name="text";
  shape_builder.init();
  const { dots, width, height } = await new Promise(res=>shape_builder.imageFile(url, res));
  // console.log('dots', dots);
  // dots.sort((a,b)=>Math.random()-Math.random());

  const step = dots.length / bricks.length;
  const promises = bricks.map((brick, idx)=>{
    // let d = dots.shift();
    let d = dots[Math.ceil(idx*step)];
    // console.log('step', Math.ceil(idx*step), idx, dots.length, step );
    const brick_width = brick_height =50;
    if(!d){
      d={
        x: random(windowWidth),
        y: random(windowHeight),
        z:1000,
      }
    }

    // console.log('brick', idx, d);

    const from = Object.assign({
      x: random(-250, 250),
      y: random(-250, 250),
      z: 5000,
      rx: random(180)-90,
      ry: 0,
      rz: 0,
      width: brick_width,
      height: brick_height,
      thick: brick_height/20,
      transparent: 0.5,
    }, brick.to);

    // delete brick.blend_img;

    const txs_fn = (n=1)=>(start, end, percentage, localStart=0, localEnd=1)=>{
      const t = allRoundEasing(percentage, localStart, localEnd, easeOutQuart, 0, 1);
      return end * (n * (t>0.5? 1-t : t)+1);
    }


    return brick.restart({
      idx,
      from,
      to: {
        x: d.x-windowWidth/2,
        y: d.y-windowHeight/2,
        z: d.z || 0,
        rx: 0,
        ry: 0,
        rz: 0,
        width: brick_width,
        height: brick_height,
        thick: brick_height/20,
        transparent: 1,
      },
      start: {
        x: 0,
        y: 0,
        z: idx/bricks.length/10,
        rx: 0,
        ry: 0,
        rz: 0,
        width: 0,
        height: 0,
        thick: 0,
        transparent: 0,
      },
      end: {
        x: 0.7,
        y: 0.7,
        z: 0.7,
        rx: 0.7,
        ry: 0.7,
        rz: 0.7,
        width: 0.7,
        height: 0.7,
        thick: 0.7,
        transparent: 0.7,
      },
      txs: {
        width: txs_fn(5),
        height: txs_fn(5),
        z: txs_fn(5),
      }
    });
  });
  promiseLock = new Promise(res=>promiseLockResolve=res);
  frameCount = 0;
  window.draw=mydraw;
  started=true;
  console.log('start');
  return promises;
}

async function start_text(text){
  effect_name="text";
  shape_builder.init();
  const { dots, width, height } = shape_builder.letter(text);

  dots.sort((a,b)=>Math.random()-Math.random());

  const promises = bricks.map((brick, idx)=>{
    // console.log('start_text', idx, brick);
    let d = dots.shift();
    const brick_width = brick_height =20;
    if(!d){
      d={
        x: random(windowWidth),
        y: random(windowHeight),
        z: 5000,
      }
    }
    d.x -= windowWidth/2;
    d.y -= windowHeight/2;

    // console.log('brick', idx, d);

    const from = Object.assign({
      x: random(-250, 250),
      y: random(-250, 250),
      z: 5000,
      rx: random(180)-90,
      ry: 0,
      rz: 0,
      width: brick_width,
      height: brick_height,
      thick: brick_height/20,
      transparent: 0.5,
    }, brick.to);
    // console.log('idx', idx, from)
    // delete brick.blend_img;

    const txs_fn = (n=1)=>(start, end, percentage, localStart=0, localEnd=1)=>{
      const t = allRoundEasing(percentage, localStart, localEnd, easeOutQuart, 0, 1);
      return end * (n * (t>0.5? 1-t : t)+1);
    }


    return brick.restart({
      idx,
      from,
      to: {
        x: d.x,
        y: d.y,
        z: d.z || 0,
        rx: 0,
        ry: 0,
        rz: 0,
        width: brick_width,
        height: brick_height,
        thick: brick_height/20,
        transparent: 1,
      },
      start: {
        x: 0,
        y: 0,
        z: idx/bricks.length/10,
        rx: 0,
        ry: 0,
        rz: 0,
        width: 0,
        height: 0,
        thick: 0,
        transparent: 0,
      },
      end: {
        x: 0.7,
        y: 0.7,
        z: 0.7,
        rx: 0.7,
        ry: 0.7,
        rz: 0.7,
        width: 0.7,
        height: 0.7,
        thick: 0.7,
        transparent: 0.7,
      },
      txs: {
        width: txs_fn(5),
        height: txs_fn(5),
        z: txs_fn(5),
      }
    });
  });
  promiseLock = new Promise(res=>promiseLockResolve=res);
  frameCount = 0;
  window.draw=mydraw;
  started=true;
  console.log('start');
  return promises;
}

const blendImg2 = (srcImg, dstImage, alpha)=>{
  const ret = srcImg.get()
  ret.blend(dstImage, 0, 0, dstImage.width, dstImage.height, 0, 0, ret.width, ret.height, HARD_LIGHT);
  return ret;
}

// const blendImg = (srcImg, dstImage, alpha)=>{
const blendImg = (dstImg, srcImg, alpha=0.8)=>{
  dstImg.resize(srcImg.width, srcImg.height);

  srcImg.loadPixels();
  dstImg.loadPixels();

  let imgRet = createImage(srcImg.width, srcImg.height);
  imgRet.loadPixels();

  // let overlay = (a,b)=>a<128 ? (a*b)>>7 : 255 - (( (255 - b) * (255 - a))>>7);
  let Foverlay = (b,a)=> a<128 ? (a*b)>>7 : 255 - (( (255 - b) * (255 - a))>>7);

  let sA  = 1;
  let dA  = alpha;
  let dA2 = (sA + dA - sA*dA);
  let out_alpha = alpha*255;

  let ch1 = 4, ch2=4, len = srcImg.width * srcImg.height *4;
  let src=srcImg.pixels, dst=dstImg.pixels, buf_ret=imgRet.pixels;

  let py=0, pz=0;
  for (let px=0;px<len;px+=ch1,py+=ch2){

    let r1=dst[py], g1=dst[py+1], b1=dst[py+2];
    let r2=src[px], g2=src[px+1], b2=src[px+2];
    // let r1=src[py], g1=src[py+1], b1=src[py+2];
    // let r2=dst[px], g2=dst[px+1], b2=dst[px+2];
    // let demultiply = 255 / dA2;
    // console.log(r1,g1,b1,r2,g2,b2);
    let f1=dA*sA, f2=dA-f1, f3=sA-f1;
    buf_ret[pz++] = f1*Foverlay(r1,r2) + f2*r1 + f3*r2;
    buf_ret[pz++] = f1*Foverlay(g1,g2) + f2*g1 + f3*g2;
    buf_ret[pz++] = f1*Foverlay(b1,b2) + f2*b1 + f3*b2;

    // buf_ret[pz++] = f1*Foverlay(r1,r2) + f2*r1 + f3*r2overlay(buf1[px], buf2[py]);
    // buf_ret[pz++] = overlay(buf1[px+1], buf2[py+1]);
    // buf_ret[pz++] = overlay(buf1[px+2], buf2[py+2]);
    buf_ret[pz++] = 255;//out_alpha;
  }
  // return dstImg;

  imgRet.updatePixels();
  return imgRet;
  /*
  let px=0, py=0, pz=0;
  const src =
  for (y = 0; y < imgRet.height; y++) {
    for (x = 0; x < imgRet.width; x++) {

      let r1=dst[py], g1=dst[py+1], b1=dst[py+2];
      let r2=src[px], g2=src[px+1], b2=src[px+2];

      image.pixels[]
      let red = random(255);
      let green = random(255);
      let blue = random(255);
      let alpha = 255;
      writeColor(img, x, y, red, green, blue, alpha);
    }
  }


  for (let px=0;px<len;px+=ch1,py+=ch2){

    // let r1=src[py], g1=src[py+1], b1=src[py+2];
    // let r2=dst[px], g2=dst[px+1], b2=dst[px+2];
    // let demultiply = 255 / dA2;

    let f1=dA*sA, f2=dA-f1, f3=sA-f1;
    buf_ret[pz++] = f1*Foverlay(r1,r2) + f2*r1 + f3*r2;
    buf_ret[pz++] = f1*Foverlay(g1,g2) + f2*g1 + f3*g2;
    buf_ret[pz++] = f1*Foverlay(b1,b2) + f2*b1 + f3*b2;

    // buf_ret[pz++] = f1*Foverlay(r1,r2) + f2*r1 + f3*r2overlay(buf1[px], buf2[py]);
    // buf_ret[pz++] = overlay(buf1[px+1], buf2[py+1]);
    // buf_ret[pz++] = overlay(buf1[px+2], buf2[py+2]);
    buf_ret[pz++] = out_alpha;
  }
  // console.log(buf_ret)
  return buf_ret;*/
}

async function add_image(url, img_in){
  brick_imgs.push(img_in ? img_in : asyncImage(url));
  // brick_imgs.push(load_image(url));
  const idx =  brick_imgs.length-1;
  const img = await brick_imgs[idx];
  debug && console.log('add_image', idx, url);
  bricks.push(new Brick({
    idx,
    img,
    texture: img,
  }))
  return;

  if(idx<montage_image_data.length){
    const montageIdx = montage_order[idx];
    // console.log('brick replace', idx, montageIdx, url);
    // const texture = blendImg(img, montage_image_data[montageIdx].blend_img, 0.6);
    const texture = img;
    bricks[montageIdx].img = img;
    bricks[montageIdx].texture = texture;
  }else{
    const idx = bricks.length;
    // console.log('brick add', idx, url);
    bricks.push(new Brick({
      idx,
      img,
      texture: img,
    }))
  }
}

async function init_image(url){
  // main_img=loadImage(`https://picsum.photos/id/237/${windowWidth}/${windowHeight}`);
  main_img = null;
  main_img = await asyncImage(url);
  // [main_img, surface_img] = await Promise.all([
  //     asyncImage(`https://picsum.photos/id/1005/5760/3840`),
  //     asyncImage(`https://openprocessing-usercontent.s3.amazonaws.com/files/user139364/visual1489900/h2215725c6151e28f0fda281b373a218c/canvas-light.jpg`)
  // ]);
  // const fit = calc_fit(windowWidth, windowHeight, main_img.width, main_img.height);
  const fit = calc_fit(windowWidth, windowHeight, 4800, 2700, true);
  console.log('fit', fit);

  tTop = fit.top;
  tLeft = fit.left;
  tWidth = fit.width;
  tHeight = fit.height;

  // const c = calc_pw(tWidth, tHeight, max_bricks);
  const c = calc_pw(tWidth, tHeight, 150);

  brick_width = Math.floor(c.bw);
  brick_height = Math.floor(c.bw);

  return;
  cols = c.cols;
  rows = c.rows;
  const tw = main_img.width / cols;
  const th = main_img.height / rows;

  console.log('tw,th',tw, th);

  tTopFix = (windowHeight - rows*brick_height)/2;
  tLeftFix = (windowWidth - cols*brick_width)/2;


  const vecStart = createVector(-1, 0);
  const vecCenter  = createVector(cols/2, rows/2);

  const data = [];
  for(let i = 0; i<rows; i++){
    for(let j = 0; j<cols; j++){
      let idx = i * rows + j;
      // bricks.push(new Brick({idx}));

      const vec = createVector(j+0.5, i+0.5).sub(vecCenter)
      // console.log(`(${i}, ${j}) ${tw*j}~${tw*j+tw}, ${th*i}~${th*i+th}`),

      data.push({
        i,j,
        blend_img: main_img.get(Math.round(tw*j), Math.round(th*i), Math.round(tw), Math.round(th)),
        x: j*brick_width + brick_width/2 - width/2+tLeftFix,
        y: i*brick_height + brick_height/2-height/2+tTopFix,
        // x: -width/2,
        // y: -height/2,
        width: brick_width,
        height: brick_height,
        start: map(idx, 0, rows*cols, 0, 0.1),
        vec,
        angel: vec.angleBetween(vecStart),
        mag: vec.mag(),
      });

    }
  }

  console.log('bb', rows, cols, rows*cols);

  data.sort((a,b)=>a.mag-b.mag+(a.angel-b.angel)/360);
  data.map((b,k)=>{
    bricks[k] ? bricks[k].blend_img = b.blend_img : false;
    b.start = map(k, 0, data.length, 0, 0.6);
  });
  // bricks.map((b,k)=>b.start = allRoundEasing(k/(bricks.length-1), 0, 1, easeInOutQuint, 0, 0.6))
  // bricks.map((b,k)=>b.start = map(k, 0, bricks.length, 0, 0.6))

  montage_image_data = data;
}

async function start_rect_image(rows, cols, start_idx){
  const brick_width = tWidth / cols , brick_height = tHeight / rows;
  // const brick_width = main_img.width / cols , brick_height = main_img.height / rows;
  const data = [];
  const vecStart = createVector(-1, 0);
  const vecCenter  = createVector(cols/2, rows/2);
  tTopFix = (windowHeight - rows*brick_height)/2;
  tLeftFix = (windowWidth - cols*brick_width)/2;

  const tw = main_img.width / cols;
  const th = main_img.height / rows;
  console.log('tw,th',tw, th);
  for(let i = 0; i<rows; i++){
    for(let j = 0; j<cols; j++){
      let idx = i * rows + j;

      const vec = createVector(j+0.5, i+0.5).sub(vecCenter)
      // console.log(`(${i}, ${j}) ${tw*j}~${tw*j+tw}, ${th*i}~${th*i+th}`),
      const main_img_part = main_img.get(Math.round(tw*j), Math.round(th*i), Math.round(tw), Math.round(th))

      data.push({
        i,j,
        blend_img: blendImg(bricks[idx].texture, main_img_part, alpha),
        x: j*brick_width + brick_width/2 - width/2+tLeftFix,
        y: i*brick_height + brick_height/2 - height/2+tTopFix,
        z: 1,
        // x: -width/2,
        // y: -height/2,
        width: brick_width,
        height: brick_height,
        start: map(idx, 0, rows*cols, 0, 0.8),
        vec,
        angel: vec.angleBetween(vecStart),
        mag: vec.mag(),
      });

    }
  }
  // console.log(data);
  effect_name = "image";
  const promises = bricks.map((brick, idx)=>{
    const data_idx = idx - start_idx;
    let d = data[data_idx] || brick.to;

    // console.log('brick', idx, d);
    // console.log(idx, 'from', brick.to);
    const from = Object.assign({}, brick.to);

    return brick.restart({
      idx,
      i: d.i,
      j: d.j,
      from,
      texture: d.blend_img,
      to: {
        x: d.x,
        y: d.y,
        z: d.z,
        rx: 0,
        ry: 0,
        rz: 0,
        width: d.width,
        height: d.height,
        thick: d.height/20,
        transparent: 1,
      },
      start: {
        x: d.start,
        y: d.start,
        z: d.start,
        rx: d.start,
        ry: d.start,
        rz: d.start,
        width: d.start,
        height: d.start,
        thick: d.start,
        transparent:  d.start,
      },
      end: {
        x: d.start+0.1,
        y: d.start+0.1,
        z: d.start+0.1,
        rx: d.start+0.1,
        ry: d.start+0.1,
        rz: d.start+0.1,
        width: d.start+0.1,
        height: d.start+0.1,
        thick: d.start+0.1,
        transparent: d.start+0.1,
      },
    });
  });

  promiseLock = new Promise(res=>promiseLockResolve=res);
  frameCount = 0;
  window.draw=mydraw;
  started=true;
  console.log('start');
  return promises;
}

async function start_image(dd=montage_image_data){
  effect_name = "image";
  const promises = bricks.map((brick, idx)=>{
    let d = montage_imadge_data[idx];
    if(!d){
      // brick.restart({idx, blend_img: null});
      // console.log('skip', idx);
      // return ;
      let i = 2, j =3;
      d={
        i, j,
        // is
        blend_img: null,
        x: random(-windowWidth/2, windowWidth/2),
        y: random(-windowHeight/2, windowHeight/2),
        // x: j*brick_width + brick_width/2 - width/2+tLeftFix,
        // y: i*brick_height + brick_height/2-height/2+tTopFix,
        // x: -width/2,
        // y: -height/2,
        width: brick_width,
        height: brick_height,
        start: map(idx, 0, rows*cols, 0, 0.9),
        texture: null,
      }
    }

    // console.log('brick', idx, d, data.length);
    // console.log(idx, 'from', brick.to);
    const from = Object.assign({
      x: random(-250, 250),
      y: random(-250, 250),
      z: 5000,
      rx: random(180)-90,
      ry: 0,
      rz: 0,
      width: d.width,
      height: d.height,
      thick: d.height/20,
      transparent: 0.5,
      // x: d.x,
      // y: d.y,
      // z: 0,
      // rx: 0,
      // ry: 0,
      // rz: 0,
      // width: d.width,
      // height: d.height,
      // thick: d.height/20,
      // transparent: 1,
    }, brick.to);

    // console.log('idx', idx, texture);

    return brick.restart({
      idx,
      i: d.i,
      j: d.j,
      from,
      to: {
        x: d.x,
        y: d.y,
        z: 0,
        rx: 0,
        ry: 0,
        rz: 0,
        width: d.width,
        height: d.height,
        thick: d.height/20,
        transparent: 1,
      },
      start: {
        x: d.start,
        y: d.start,
        z: d.start,
        rx: d.start,
        ry: d.start,
        rz: d.start,
        width: d.start,
        height: d.start,
        thick: d.start,
        transparent:  d.start,
      },
      end: {
        x: d.start+0.1,
        y: d.start+0.1,
        z: d.start+0.1,
        rx: d.start+0.1,
        ry: d.start+0.1,
        rz: d.start+0.1,
        width: d.start+0.1,
        height: d.start+0.1,
        thick: d.start+0.1,
        transparent: d.start+0.1,
      },
    });
  });

  promiseLock = new Promise(res=>promiseLockResolve=res);
  frameCount = 0;
  window.draw=mydraw;
  started=true;
  console.log('start');
  return promises;
}

async function exit_image(){

  const promises = bricks.map((brick, idx)=>{

    const randomTo = ()=>{
      return {
        x: windowWidth,
        y: windowHeight,
        z: 0,
        rx: 0,
        ry: 0,
        rz: 0,
        width: brick.to.width,
        height: brick.to.height,
        thick: brick.to.thick,
        transparent: 1,
      }
    }

    const randomStart = ()=>{
      return random([{
        x: brick.start.x,
        y: brick.start.y,
        z: brick.start.z,
        rx: brick.start.rx,
        ry: brick.start.ry,
        rz: brick.start.rz,
        width: brick.start.width,
        height: brick.start.height,
        thick: brick.start.thick,
        transparent:  brick.start.transparent,
      }, {
        x: allRoundEasing(brick.i, 0, cols, easeOutQuart),
        y: allRoundEasing(brick.i, 0, cols, easeOutQuart),
        z: allRoundEasing(brick.i, 0, cols, easeOutQuart),
        rx: allRoundEasing(brick.i, 0, cols, easeOutQuart),
        ry: allRoundEasing(brick.i, 0, cols, easeOutQuart),
        rz: allRoundEasing(brick.i, 0, cols, easeOutQuart),
        width: allRoundEasing(brick.i, 0, cols, easeOutQuart),
        height: allRoundEasing(brick.i, 0, cols, easeOutQuart),
        thick: allRoundEasing(brick.i, 0, cols, easeOutQuart),
        transparent: allRoundEasing(brick.i, 0, cols, easeOutQuart),
      }]);
    }
    // console.log('brick', idx, d, data.length);
    // console.log(idx, 'from', brick.to);


    const randomEnd = ()=>{
      return {
        x: brick.end.x,
        y: brick.end.y,
        z: brick.end.z,
        rx: brick.end.rx,
        ry: brick.end.ry,
        rz: brick.end.rz,
        width: brick.end.width,
        height: brick.end.height,
        thick: brick.end.thick,
        transparent:  brick.end.transparent,
      }
    }

    const from = Object.assign({}, brick.to);
    const to = randomTo();
    const start = randomStart();
    const end = randomEnd();

    return brick.restart({
      idx,
      from,
      to,
      start,
      end,
    });
  });
  promiseLock = new Promise(res=>promiseLockResolve=res);
  frameCount = 0;
  started=true;
  console.log('exit');
  return promises;
}

document.addEventListener("keydown", keyDownTextField, false);

function keyDownTextField(e) {
  var keyCode = e.keyCode;
  if(keyCode==32) {
    add_image(`https://picsum.photos/seed/${brick_imgs.length%20}/200/200`);
  }
}

export function setup() {
  console.log('setup');
  console.trace();

  createCanvas(windowWidth, windowHeight, WEBGL);
  angleMode(DEGREES);
  orbitControl();
  controller();
}

export function draw() {

}

async function async_fetch_images(){
  const url = "https://backend2.linein.cc/api/fetch_montage_state/?activity="+activity;
  const z = (await axios.get(url)).data;

  console.log('async', z.length);
  // z.forEach(zz=>{
  //   const img = await asyncImage()
  //   if(true || !montage_processed.hasOwnProperty(zz)){
  //     montage_processed[zz]=true;
  //     // add_image(`https://storage.googleapis.com/linein/2311_bsjttdtq/p/${zz}_s.jpg`)
  //     add_image(`https://storage.googleapis.com/linein/${activity}/montage/${zz}_photo_ori.jpg?a=1`)
  //   }
  // });
  const d = [];
  for(let i =0; i < max_bricks; i++){
    try{
      const zz = z[i % z.length];
      debug && console.log('imgs', i, zz);
      // const url = `https://storage.googleapis.com/linein/2311_bsjttdtq/p/${zz}_ori.jpg`;
      const url = `https://storage.googleapis.com/linein/${activity}/montage/${zz}_photo_ori.jpg?a=1`;
      //     // add_image()
      // const img = await asyncImage(url);
      d.push(await add_image(url));
      document.title= Math.round((i+1)*100 / max_bricks)+"%";
    }catch(err){}
  }
  // await Promise.all(d);

  document.title= "start_drawing";
  montage_order = [...Array(max_bricks).keys()];
  // setTimeout(async_fetch_images, 20*1000);
}

async function controller(){

  // const url = "https://backend2.linein.cc/activity/2004_ghvylcrg/albums";
  // const z = await axios.get(url);
  // const album = z.data[''];
  // console.log('z', z);
  frameRate(30);
  // bg=loadImage();
  // main_img = await asyncImage('../img/universe.jpg');

  // await brick_init();
  // await init_image(`https://storage.googleapis.com/linein/2311_bsjttdtq/montage.png?0.20618558364007566`);
  // await init_image(`https://storage.googleapis.com/linein/${activity}/montage.png?0.20618558364007566`);
  // await init_image(`https://storage.googleapis.com/linein/2409_lneddrzy/montage.png?0.20618558364007566`);
  await init_image(`https://storage.googleapis.com/linein/${main_activity}/montage.png?0.20618558364007566`);
  // await init_image(`https://storage.googleapis.com/linein/${activity}/montage.png?1`);
  await async_fetch_images();
  // const mock = ()=>{}
  // const effects = [mock];

  // let current, next;

  // current = new sample(effects)(para);
  // await current.prepare();
  let idx = -1;
  const strs= ["Dolphin"];
  // const strs= ["全球人壽"];

  document.title= "start_drawing2";
  await new Promise(res=>setTimeout(res, 3*1000));
  document.title= "start_drawing3";
  let effects = [
    // [()=>start_text("OKOKOK"), 100],
    // [()=>start_text(""), 50],
    [()=>start_rect_image(nrows, ncols,0), 1000],
    // [()=>start_text(""), 50],
    // [()=>start_rect_image(3,12,36), 100],
    // [()=>start_rect_image(3,12,72), 100],
    // [()=>start_rect_image(3,12,108), 100],
    // [()=>start_rect_image(3,12,144), 100],
    // [()=>start_rect_image(3,12,180), 100],
    // [()=>start_text("U"), 50],
    // [()=>start_rect_image(4,16,64), 100],
    // [()=>start_text("D"), 50],
    // [()=>start_rect_image(4,16,128), 100],
    // [()=>start_text("I"), 50],
    // [()=>start_rect_image(4,16,192), 100],
    // [()=>start_img("https://storage.googleapis.com/linein/2007_lafmwrny/audi_logo_full2.png"), 300],
    // [()=>start_rect_image(9,16,0), 100],
    // [()=>start_fade(`https://storage.googleapis.com/linein/2007_lafmwrny/audi_logo_full.png`), 300],
    // [()=>start_fade(`https://storage.googleapis.com/linein/2409_lneddrzy/2409_lneddrzy_overlay2.png`), 300],
        // [()=>start_rect_image(4,16,64), 1000],
    // [()=>start_rect_image(4,16,64), 100],
    // [()=>start_text("AUDI"), 100],
    // [()=>start_text("麥力共好"), 100],
    // [()=>start_text("歡聚四十"), 100],
    // [()=>start_image(), 100],


    // [()=>start_text("Let's"), 60],
    // [()=>start_text("Party"), 60],
    // ...strs.map(text=>[()=>start_text(text), 90]),
  ];

  while(idx<effects.length){
    idx= (idx+1) % effects.length;
    const effect = effects[idx];
    console.log('promisStart', idx);
    scene_frames = effect[1];
    const promisStart = effect[0];
    await promisStart();
    await promiseLock;
    started=false;
    if(!loops){ break; }
  }
  /*
  while(true){
    idx= (idx+1) % effects.length;

    // idx = (idx+1)%album.length;
    // console.log('start', idx, url);
    // let promisStart = sample([
    //   ()=>start_image(album[idx%album.length].url),
    //   ()=>start_text(strs[idx%strs.length])
    // ]);
    console.log('promisStart', promisStart);
    scene_frames
    await promisStart();
    await promiseLock;
    started=false;


    // scene_frames=90;
    // let promiseExit = await exit_image();
    // await promiseLock;
    // started=false;
    // await Promise.all(promises);

    // next = new sample(effects)(para);
    // next.prepare();

    // await current.start();
    // first = next;
  }
  */
}

function mydraw() {
    if(!started){
      return;
    }
    const frames = scene_frames;
    if(frameCount>frames){
      promiseLockResolve(true);
      return;
    }
    // console.log('framecount', frameCount/frames, frameCount);
    if(frameCount % 100==0){ console.log('framecount', frameCount/frames) }
    background(255, 10);
    // ambientLight(60, 60, 60);
    // imageMode(CENTER);
    orbitControl();
    noStroke()
    // Map the fov to the mouse x-axis
  // let fov = map(mouseX, 0, width, 0, PI);
  // let cZ = (height/2.0) / tan((PI/3)/2.0);

  // // Set the perspective to the fov
  // perspective(fov, float(width)/float(height),
  //             cZ/10.0, cZ*10.0);

  // text("put your p5.js code here",10, frameCount % height);
  const percentage = (frameCount> frames ? (frameCount % frames): frameCount )/frames;
  // const percentage = 1.0;

  // console.log('draw', percentage, frameCount, bricks.length);
  // const frames = 20*1000;
    // const percentage = (deltaTime % frames)/frames;
    // console.log(deltaTime);

    // rotateZ(map(percentage, 0.2, 0.3, -90, 0, true));
    // rotateZ(allRoundEasing(percentage, 0.2, 0.3, easeOutQuart, -90, 0));
    // rotateZ(allRoundEasing(percentage, 0.2, 0.3, easeOutQuart, -90, 0));

    if(effect_name=="image" || effect_name=="text"){
      bricks.forEach(b=>{
        if(b.to){
          b.draw(percentage)
        }
      });
    }

    if(effect_name=="fade"){
      bricks.forEach(b=>{
        if(b.to){
          b.draw(1)
        }
      });
      fade_img.draw(percentage);
    }

    // translate(0,0,-1);
    // box(windowWidth, windowHeight,1);

    // console.log('draw');
		// blendMode(MULTIPLY)
		// image(surface_img,0,0)
}

export function windowResized() {
    resizeCanvas(windowWidth, windowHeight);
}


// let img; // 声明变量 'img'

// function setup() {
//   createCanvas(720, 400);
//   img = loadImage(`https://picsum.photos/seed/1/200/200`); // 加载图像
// }

// function draw() {
//   // 在坐标(0, 0)，显示原图大小的图像
//   image(img, 100, 100);
//   // 在坐标(0, 高度/2)，显示一半原图大小的图像
//   image(img, 100, height / 2, img.width / 2, img.height / 2);
// }


/*let x, y, backgroundColor;

const width = 500;
const height = 500;

export function setup() {
  createCanvas(width, height);
  backgroundColor = color(random(255), random(255), random(255));

  x = random(width);
  y = height / 2;
}

export function draw() {
  background(backgroundColor);
  fill(color(255, 0, 0));
  ellipse(x, y, 100, 100);

  x = (x + 1) % width;
}

export function mousePressed() {
  backgroundColor = color(random(255), random(255), random(255));
}
*/