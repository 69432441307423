import { allRoundEasing, easeOutQuart } from './easing';
import { merge } from 'lodash';

export class Brick {
  constructor(config) {
    // this.x = x;
    // this.y = y;
    // this.z = z;
    // this.width = width;
    // this.height = height;
    // this.start = start ||  ; // 0~0.98
    // this.duration = 0.1; //random(100) / 100  *0.03; //0~0.0.1
    // this.dx = random(-250, 250);
    // this.dy = random(-250, 250);
    // this.dz = -5000;
    merge(this, config);
    // if(config?.idx==100){
      // console.log('brick init', config);
    // }
    // this.drx = random(180)-90; //0~0.0.2

  }

  restart(config){
    const that = this;
    this.playPromise = new Promise(res=>that.playPromiseRes = res);

    const txs_fn = (start, end, percentage, localStart=0, localEnd=1)=>{
      // console.log(percentage, localStart, localEnd);
      return allRoundEasing(percentage, localStart, localEnd, easeOutQuart, 0, 1)*(end-start)+start;
    }
    // console.log(that.idx, 'restart', config)
    const defaultConfig = {
      from: {
        x:0,
        y:0,
        z:0,
        rx:0,
        ry:0,
        rz:0,
        width:0,
        height:0,
        thick:0,
        transparent: 0,
      },
      to: {
        x:0,
        y:0,
        z:0,
        rx:0,
        ry:0,
        rz:0,
        width:0,
        height:0,
        thick:0,
        transparent: 0,
      },
      start: {
        x:0,
        y:0,
        z:0,
        rx:0,
        ry:0,
        rz:0,
        width:1,
        height:1,
        thick:1,
        transparent: 1,
      },
      end: {
        x:1,
        y:1,
        z:1,
        rx:1,
        ry:1,
        rz:1,
        width:20,
        height:20,
        thick:20,
        transparent: 1,
      },
      txs: {
        x: txs_fn,
        y: txs_fn,
        z: txs_fn,
        rx: txs_fn,
        ry: txs_fn,
        rz: txs_fn,
        width: txs_fn,
        height: txs_fn,
        thick: txs_fn,
        transparent: txs_fn,
      }
    };
    merge(this, defaultConfig, config);
    // Object.assign(this, defaultConfig, config);

    // this.texture = this.texture;
    // this.blend_img = blend_img;
    return this.playPromise;
  }

  draw(global_pct){
    // if(global_pct>=1){
    //   this.playPromiseRes(this.to);
    // }
    push();

    // if(this.idx ==100){
    //   console.log(
    //     this.from.width, this.to.width, global_pct, this.start.width, this.end.width, this,
    //     {
    //     x: this.txs.x(this.from.x, this.to.x, global_pct, this.start.x, this.end.x),
    //     y: this.txs.y(this.from.y, this.to.y, global_pct, this.start.y, this.end.y),
    //     z: this.txs.z(this.from.z, this.to.z, global_pct, this.start.z, this.end.z),
    //     rx: this.txs.rx(this.from.rx, this.to.rx, global_pct, this.start.rx, this.end.rx),
    //     ry: this.txs.ry(this.from.ry, this.to.ry, global_pct, this.start.ry, this.end.ry),
    //     rz: this.txs.rz(this.from.rz, this.to.rz, global_pct, this.start.rz, this.end.rz),
    //     width: this.txs.width(this.from.width, this.to.width, global_pct, this.start.width, this.end.width),
    //     height: this.txs.height(this.from.height, this.to.height, global_pct, this.start.height, this.end.height),
    //     thick: this.txs.thick(this.from.thick, this.to.thick, global_pct, this.start.thick, this.end.thick),
    //     transparent: this.txs.transparent(this.from.transparent, this.to.transparent, global_pct, this.start.transparent, this.end.transparent),
    //   });
    // }

    // let tx = finished_duration>0 ? sin(finished_duration*360*5)*this.width*0.05*this.global_ready_fade : 0
    // let ty = finished_duration>0 ? sin(finished_duration*360*5)*this.height*0.05*this.global_ready_fade : 0
    // let tz = finished_duration>0 ? sin(finished_duration*360*5)*this.width/20*this.global_ready_fade : 0

    const newTint =  this.txs.transparent(this.from.transparent, this.to.transparent, global_pct, this.start.transparent, this.end.transparent);
    if(newTint == this.oldTint){
      this.oldTint = newTint;
    }

    translate(
      this.txs.x(this.from.x, this.to.x, global_pct, this.start.x, this.end.x),
      this.txs.y(this.from.y, this.to.y, global_pct, this.start.y, this.end.y),
      this.txs.z(this.from.z, this.to.z, global_pct, this.start.z, this.end.z)-50,
    );

    rotateX(this.txs.rx(this.from.rx, this.to.rx, global_pct, this.start.rx, this.end.rx));
    rotateY(this.txs.ry(this.from.ry, this.to.ry, global_pct, this.start.ry, this.end.ry));
    rotateZ(this.txs.rz(this.from.rz, this.to.rz, global_pct, this.start.rz, this.end.rz));

    this.texture ? texture(this.texture) : this.blend_img && texture(this.blend_img);

    box(
      this.txs.width(this.from.width, this.to.width, global_pct, this.start.width, this.end.width),
      this.txs.height(this.from.height, this.to.height, global_pct, this.start.height, this.end.height),
      this.txs.thick(this.from.thick, this.to.thick, global_pct, this.start.thick, this.end.thick)
    );

    pop();
  }
}