var S = {
  init: function () {
      S.Drawing.init('.canvas');
      document.body.classList.add('body--ready');
      //想說的話
      S.UI.simulate("zhuzhu | Thanks| --Kevin|#countdown 3|#time");
      S.Drawing.loop(function () {
          S.Shape.render();
      });
  }
};//JSON的寫法，方便調用裏面的變量和function
S.Drawing = (function () {
  var canvas,
      context,
      renderFn,
      requestFrame = window.requestAnimationFrame ||
          window.webkitRequestAnimationFrame ||
          window.mozRequestAnimationFrame ||
          window.oRequestAnimationFrame ||
          window.msRequestAnimationFrame ||
          function (callback) {
              window.setTimeout(callback, 1000 / 60);
          };//實現重複動畫效果的方法，以上封裝後可兼容所有浏覽器
  return {
      init: function (el) {
          canvas = document.querySelector(el);
          context = canvas.getContext('2d');
          this.adjustCanvas();
          window.addEventListener('resize', function (e) {
              S.Drawing.adjustCanvas();
          });
      },//初始畫布
      loop: function (fn) {
          renderFn = !renderFn ? fn : renderFn;
          this.clearFrame();
          renderFn();
          requestFrame.call(window, this.loop.bind(this));
      },//循環動畫
      adjustCanvas: function () {
          canvas.width = window.innerWidth - 100;
          canvas.height = window.innerHeight - 30;
      },//設置屏幕自适應畫布
      clearFrame: function () {
          context.clearRect(0, 0, canvas.width, canvas.height);
      },//清空畫布
      getArea: function () {
          return {w: canvas.width, h: canvas.height};
      },//獲取畫布長寬
      drawCircle: function (p, c) {
          context.fillStyle = c.render();
          context.beginPath();
          context.arc(p.x, p.y, p.z, 0, 2 * Math.PI, true);
          context.closePath();
          context.fill();
      }//畫小圓
  };
}());

const ShapeBuilder = function () {
    var gap = 30,
        shapeCanvas = document.createElement('canvas'),
        shapeContext = shapeCanvas.getContext('2d'),
        fontSize = 500,
        fontFamily = '微軟正黑體, Avenir, Helvetica Neue, Helvetica, Arial, sans-serif';

    function fit() {
      shapeCanvas.width = Math.floor(window.innerWidth / gap) * gap;
      shapeCanvas.height = Math.floor(window.innerHeight / gap) * gap;
      shapeContext.fillStyle = 'red';
      shapeContext.textBaseline = 'middle';
      shapeContext.textAlign = 'center';
      // document.body.append(shapeCanvas);
    }

    function processCanvas() {
      var pixels = shapeContext.getImageData(0, 0, shapeCanvas.width, shapeCanvas.height).data,
          dots = [],
          x = 0,
          y = 0,
          fx = shapeCanvas.width,
          fy = shapeCanvas.height,
          w = 0,
          h = 0;
      console.log(pixels.length);
      console.log('shapeContext', shapeContext);
      for (var p = 0; p < pixels.length; p += (4 * gap)) {
        if (pixels[p + 3] > 0) {
          dots.push({
            x: x,
            y: y
          });

          w = x > w ? x : w;
          h = y > h ? y : h;
          fx = x < fx ? x : fx;
          fy = y < fy ? y : fy;
        }

        x += gap;

        if (x >= shapeCanvas.width) {
          x = 0;
          y += gap;
          p += gap * 4 * shapeCanvas.width;
        }
      }

      return { dots: dots, w: w + fx, h: h + fy };
    }

    function setFontSize(s) {
      shapeContext.font = 'bold ' + s + 'px ' + fontFamily;
    }

    function isNumber(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    }

    return {
      init: function () {
        fit();
        window.addEventListener('resize', fit);
      },

      imageFile: function (url, callback, width, height) {
        var image = new Image();
        // ,a = S.Drawing.getArea();
        console.log('imageFile', url);
        image.onload = function () {
          console.log('imageFile OK', url);
          const wo = width||this.width;
          const ho = height||this.height;

          let ratio = Math.min(shapeCanvas.width / wo, shapeCanvas.height / ho);
          let w = wo * ratio, h = ho * ratio;
          let left = (shapeCanvas.width - w)/2, top = (shapeCanvas.height - h)/2;
          shapeContext.clearRect(0, 0, shapeCanvas.width, shapeCanvas.height);
          shapeContext.drawImage(this, left, top, w, h) ; //, this.width * 0.6, this.height * 0.6);
          console.log('imageFile position',left, top, w, h);
          callback(processCanvas());
        };

        image.onerror = function () {
          console.log('imageFile3', url);
          callback(ShapeBuilder.letter('What?'));
        };
        image.setAttribute('crossOrigin', 'Anonymous');
        image.src = url;
      },

      circle: function (d) {
        var r = Math.max(0, d) / 2;
        shapeContext.clearRect(0, 0, shapeCanvas.width, shapeCanvas.height);
        shapeContext.beginPath();
        shapeContext.arc(r * gap, r * gap, r * gap, 0, 2 * Math.PI, false);
        shapeContext.fill();
        shapeContext.closePath();

        return processCanvas();
      },

      letter: function (l) {
        var s = 0;

        setFontSize(fontSize);
        s = Math.min(fontSize,
                    (shapeCanvas.width / shapeContext.measureText(l).width) * 0.8 * fontSize,
                    (shapeCanvas.height / fontSize) * (isNumber(l) ? 1 : 0.45) * fontSize);
        setFontSize(s);

        shapeContext.clearRect(0, 0, shapeCanvas.width, shapeCanvas.height);
        shapeContext.fillText(l, shapeCanvas.width / 2, shapeCanvas.height / 2);

        return processCanvas();
      },

      rectangle: function (w, h) {
        var dots = [],
            width = gap * w,
            height = gap * h;

        for (var y = 0; y < height; y += gap) {
          for (var x = 0; x < width; x += gap) {
            dots.push({
              x: x,
              y: y,
            });
          }
        }

        return { dots: dots, w: width, h: height };
      }
    };
};

export default ShapeBuilder;