module.exports={
  // no easing, no acceleration
  allRoundEasing: (t, t1, t2, f, start=0, end=1) => f(map(t, t1, t2, 0, 1, true))*(end-start)+start,

  linear: t => t,
  // accelerating from zero velocity
  easeInQuad: t => t*t,
  // decelerating to zero velocity
  easeOutQuad: t => t*(2-t),
  // acceleration until halfway, then deceleration
  easeInOutQuad: t => t<.5 ? 2*t*t : -1+(4-2*t)*t,
  // accelerating from zero velocity
  easeInCubic: t => t*t*t,
  // decelerating to zero velocity
  easeOutCubic: t => (--t)*t*t+1,
  // acceleration until halfway, then deceleration
  easeInOutCubic: t => t<.5 ? 4*t*t*t : (t-1)*(2*t-2)*(2*t-2)+1,
  // accelerating from zero velocity
  easeInQuart: t => t*t*t*t,
  // decelerating to zero velocity
  easeOutQuart: t => 1-(--t)*t*t*t,
  // acceleration until halfway, then deceleration
  easeInOutQuart: t => t<.5 ? 8*t*t*t*t : 1-8*(--t)*t*t*t,
  // accelerating from zero velocity
  easeInQuint: t => t*t*t*t*t,
  // decelerating to zero velocity
  easeOutQuint: t => 1+(--t)*t*t*t*t,
  // acceleration until halfway, then deceleration
  easeInOutQuint: t => t<.5 ? 16*t*t*t*t*t : 1+16*(--t)*t*t*t*t
}
/*export function easeInQuad(elapsed, initialValue, amountOfChange, duration) {
	return amountOfChange * (elapsed /= duration) * elapsed + initialValue;
}

export function easeOutQuad(elapsed, initialValue, amountOfChange, duration) {
	return -amountOfChange * (elapsed /= duration) * (elapsed - 2) + initialValue;
}

export function easeInOutQuad(elapsed, initialValue, amountOfChange, duration) {
	if ((elapsed /= duration / 2) < 1) {
		return amountOfChange / 2 * elapsed * elapsed + initialValue;
	}
	return -amountOfChange / 2 * (--elapsed * (elapsed - 2) - 1) + initialValue;
}

export function easeInCubic(elapsed, initialValue, amountOfChange, duration) {
	return amountOfChange * (elapsed /= duration) * elapsed * elapsed + initialValue;
}

export function easeOutCubic(elapsed, initialValue, amountOfChange, duration) {
	return amountOfChange * ((elapsed = elapsed / duration - 1) * elapsed * elapsed + 1) + initialValue;
}

export function easeInOutCubic(elapsed, initialValue, amountOfChange, duration) {
	if ((elapsed /= duration / 2) < 1) {
		return amountOfChange / 2 * elapsed * elapsed * elapsed + initialValue;
	}
	return amountOfChange / 2 * ((elapsed -= 2) * elapsed * elapsed + 2) + initialValue;
}

export function easeInQuart(elapsed, initialValue, amountOfChange, duration) {
	return amountOfChange * (elapsed /= duration) * elapsed * elapsed * elapsed + initialValue;
}

export function easeOutQuart(elapsed, initialValue, amountOfChange, duration) {
	return -amountOfChange * ((elapsed = elapsed / duration - 1) * elapsed * elapsed * elapsed - 1) + initialValue;
}

export function easeInOutQuart(elapsed, initialValue, amountOfChange, duration) {
	if ((elapsed /= duration / 2) < 1) {
		return amountOfChange / 2 * elapsed * elapsed * elapsed * elapsed + initialValue;
	}
	return -amountOfChange / 2 * ((elapsed -= 2) * elapsed * elapsed * elapsed - 2) + initialValue;
}

export function easeInQuint(elapsed, initialValue, amountOfChange, duration) {
	return amountOfChange * (elapsed /= duration) * elapsed * elapsed * elapsed * elapsed + initialValue;
}

export function easeOutQuint(elapsed, initialValue, amountOfChange, duration) {
	return amountOfChange * ((elapsed = elapsed / duration - 1) * elapsed * elapsed * elapsed * elapsed + 1) + initialValue;
}

export function easeInOutQuint(elapsed, initialValue, amountOfChange, duration) {
	if ((elapsed /= duration / 2) < 1) {
		return amountOfChange / 2 * elapsed * elapsed * elapsed * elapsed * elapsed + initialValue;
	}
	return amountOfChange / 2 * ((elapsed -= 2) * elapsed * elapsed * elapsed * elapsed + 2) + initialValue;
}

export function easeInSine(elapsed, initialValue, amountOfChange, duration) {
	return -amountOfChange * Math.cos(elapsed / duration * (Math.PI / 2)) + amountOfChange + initialValue;
}

export function easeOutSine(elapsed, initialValue, amountOfChange, duration) {
	return amountOfChange * Math.sin(elapsed / duration * (Math.PI / 2)) + initialValue;
}

export function easeInOutSine(elapsed, initialValue, amountOfChange, duration) {
	return -amountOfChange / 2 * (Math.cos(Math.PI * elapsed / duration) - 1) + initialValue;
}

export function easeInExpo(elapsed, initialValue, amountOfChange, duration) {
	return elapsed === 0 ? initialValue : amountOfChange * Math.pow(2, 10 * (elapsed / duration - 1)) + initialValue;
}

export function easeOutExpo(elapsed, initialValue, amountOfChange, duration) {
	return elapsed === duration
		? initialValue + amountOfChange
		: amountOfChange * (-Math.pow(2, -10 * elapsed / duration) + 1) + initialValue;
}

export function easeInOutExpo(elapsed, initialValue, amountOfChange, duration) {
	if (elapsed === 0) {
		return initialValue;
	}
	if (elapsed === duration) {
		return initialValue + amountOfChange;
	}
	if ((elapsed /= duration / 2) < 1) {
		return amountOfChange / 2 * Math.pow(2, 10 * (elapsed - 1)) + initialValue;
	}
	return amountOfChange / 2 * (-Math.pow(2, -10 * --elapsed) + 2) + initialValue;
}

export function easeInCirc(elapsed, initialValue, amountOfChange, duration) {
	return -amountOfChange * (Math.sqrt(1 - (elapsed /= duration) * elapsed) - 1) + initialValue;
}

export function easeOutCirc(elapsed, initialValue, amountOfChange, duration) {
	return amountOfChange * Math.sqrt(1 - (elapsed = elapsed / duration - 1) * elapsed) + initialValue;
}

export function easeInOutCirc(elapsed, initialValue, amountOfChange, duration) {
	if ((elapsed /= duration / 2) < 1) {
		return -amountOfChange / 2 * (Math.sqrt(1 - elapsed * elapsed) - 1) + initialValue;
	}
	return amountOfChange / 2 * (Math.sqrt(1 - (elapsed -= 2) * elapsed) + 1) + initialValue;
}

export function easeInElastic(elapsed, initialValue, amountOfChange, duration) {
	let s = 1.70158;
	let p = 0;
	let a = amountOfChange;
	if (elapsed === 0) {
		return initialValue;
	}
	if ((elapsed /= duration) === 1) {
		return initialValue + amountOfChange;
	}
	if (!p) {
		p = duration * 0.3;
	}
	if (a < Math.abs(amountOfChange)) {
		a = amountOfChange;
		s = p / 4;
	} else {
		s = p / (2 * Math.PI) * Math.asin(amountOfChange / a);
	}
	return -(a * Math.pow(2, 10 * (elapsed -= 1)) * Math.sin((elapsed * duration - s) * (2 * Math.PI) / p)) + initialValue;
}

export function easeOutElastic(elapsed, initialValue, amountOfChange, duration) {
	let s = 1.70158;
	let p = 0;
	let a = amountOfChange;
	if (elapsed === 0) {
		return initialValue;
	}
	if ((elapsed /= duration) === 1) {
		return initialValue + amountOfChange;
	}
	if (!p) {
		p = duration * 0.3;
	}
	if (a < Math.abs(amountOfChange)) {
		a = amountOfChange;
		s = p / 4;
	} else {
		s = p / (2 * Math.PI) * Math.asin(amountOfChange / a);
	}
	return a * Math.pow(2, -10 * elapsed) * Math.sin((elapsed * duration - s) * (2 * Math.PI) / p) + amountOfChange + initialValue;
}

export function easeInOutElastic(elapsed, initialValue, amountOfChange, duration) {
	let s = 1.70158;
	let p = 0;
	let a = amountOfChange;
	if (elapsed === 0) {
		return initialValue;
	}
	if ((elapsed /= duration / 2) === 2) {
		return initialValue + amountOfChange;
	}
	if (!p) {
		p = duration * (0.3 * 1.5);
	}
	if (a < Math.abs(amountOfChange)) {
		a = amountOfChange;
		s = p / 4;
	} else {
		s = p / (2 * Math.PI) * Math.asin(amountOfChange / a);
	}
	if (elapsed < 1) {
		return -0.5 * (a * Math.pow(2, 10 * (elapsed -= 1)) * Math.sin((elapsed * duration - s) * (2 * Math.PI) / p)) + initialValue;
	}
	return (
		a * Math.pow(2, -10 * (elapsed -= 1)) * Math.sin((elapsed * duration - s) * (2 * Math.PI) / p) * 0.5 + amountOfChange + initialValue
	);
}

export function easeInBack(elapsed, initialValue, amountOfChange, duration, s = 1.70158) {
	return amountOfChange * (elapsed /= duration) * elapsed * ((s + 1) * elapsed - s) + initialValue;
}

export function easeOutBack(elapsed, initialValue, amountOfChange, duration, s = 1.70158) {
	return amountOfChange * ((elapsed = elapsed / duration - 1) * elapsed * ((s + 1) * elapsed + s) + 1) + initialValue;
}

export function easeInOutBack(
	elapsed,
	initialValue,
	amountOfChange,
	duration,
	s = 1.70158
) {
	if ((elapsed /= duration / 2) < 1) {
		return amountOfChange / 2 * (elapsed * elapsed * (((s *= 1.525) + 1) * elapsed - s)) + initialValue;
	}
	return amountOfChange / 2 * ((elapsed -= 2) * elapsed * (((s *= 1.525) + 1) * elapsed + s) + 2) + initialValue;
}

export function easeInBounce(elapsed, initialValue, amountOfChange, duration) {
	return amountOfChange - easeOutBounce(duration - elapsed, 0, amountOfChange, duration) + initialValue;
}

export function easeOutBounce(elapsed, initialValue, amountOfChange, duration) {
	if ((elapsed /= duration) < 1 / 2.75) {
		return amountOfChange * (7.5625 * elapsed * elapsed) + initialValue;
	} else if (elapsed < 2 / 2.75) {
		return amountOfChange * (7.5625 * (elapsed -= 1.5 / 2.75) * elapsed + 0.75) + initialValue;
	} else if (elapsed < 2.5 / 2.75) {
		return amountOfChange * (7.5625 * (elapsed -= 2.25 / 2.75) * elapsed + 0.9375) + initialValue;
	} else {
		return amountOfChange * (7.5625 * (elapsed -= 2.625 / 2.75) * elapsed + 0.984375) + initialValue;
	}
}

export function easeInOutBounce(elapsed, initialValue, amountOfChange, duration) {
	if (elapsed < duration / 2) {
		return easeInBounce(elapsed * 2, 0, amountOfChange, duration) * 0.5 + initialValue;
	}
	return easeOutBounce(elapsed * 2 - duration, 0, amountOfChange, duration) * 0.5 + amountOfChange * 0.5 + initialValue;
}*/