export function calc_pw(w,h,n){
    // const bw = Math.ceil(Math.sqrt(w*h/n));
    const bw = Math.floor(Math.sqrt(w*h/n));
    return {bw, cols: Math.floor(w/bw), rows: Math.floor(h/bw)};
}

export function calc_fit(outW, outH, inW, inH, nolarger=false){
  let ratio = Math.min(outW/inW, outH/inH, nolarger);
  if(nolarger && ratio > 1) ratio = 1;
  
  const width = Math.round(inW * ratio),
        height= Math.round(inH * ratio),
        top = Math.round((outH - height)/2),
        left = Math.round((outW - width)/2);

  return {top, left, width, height/*, ratio, outW, outH, inW, inH*/};
}

export const asyncImage = url => new Promise((res, rej)=>{
  loadImage(url, img=>res(img), evt=>rej(evt));
})

export const parseQuery = (s=location.search) =>{
  const vars = {};
  var parts = s.replace(/[?&]+([^=&]+)=([^&^#]*)/gi, function(m,key,value) {
      vars[key] = value;
  });
  return vars;
}
